import { Seo } from "gatsby-plugin-wpgraphql-seo";
import { useSinglePageByDatabaseId } from "../../queries/wpPage/useSinglePageByDatabaseId";
import RVODefaultTemplate from "../04-templates/RVODefaultTemplate/RVODefaultTemplate";

export const DatenschutzAppPage = (): JSX.Element => {
  const datenschutzAppPage = useSinglePageByDatabaseId(3099);

  return (
    <>
      <Seo post={datenschutzAppPage} />

      <RVODefaultTemplate page={datenschutzAppPage} />
    </>
  );
};

export default DatenschutzAppPage;
